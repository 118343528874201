<template>
  <v-container>
    <v-row dense>
      <v-col cols="4">
        <v-select
          :items="courses"
          item-text="name"
          item-value="_id"
          v-model="selectedCourse"
          clearable
          :label="$t('courses.myCourses')"
        >
          <template v-slot:append-outer v-if="isCourseOwner">
            <v-btn dense icon @click="createCourse"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            <v-btn
              dense
              icon
              v-if="selectedCourse && isCourseOwner"
              @click="prepareCourseUpdate"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <!--
            <v-btn dense icon v-if="selectedCourse" @click="deleteCourse"
              ><v-icon>mdi-delete</v-icon></v-btn
            > -->
          </template>
        </v-select>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4" v-if="(tab === 1 || tab === 2) && authors.length > 0">
        <v-select
          :items="authors"
          item-text="name"
          item-value="id"
          v-model="selectedAuthor"
          clearable
          label="Forfattere"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab class="text-caption">
            <v-badge
              :content="'[' + projects.length + ']'"
              tile
              inline
              color="transparent"
            >
              <div v-html="$t('learningDesign.myLearningDesigns')" />
            </v-badge>
          </v-tab>
          <v-tab class="text-caption">
            <v-badge
              :content="'[' + sharedProjects.length + ']'"
              tile
              inline
              color="transparent"
            >
              <div v-html="$t('learningDesign.sharedLearningDesigns')" />
            </v-badge>
          </v-tab>
          <v-tab class="text-caption" v-if="isCourseOwner">
            <v-badge
              :content="'[' + otherProjects.length + ']'"
              tile
              inline
              color="transparent"
            >
              <div v-html="$t('learningDesign.allOtherLearningDesigns')" />
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <!-- Mine undervisningsdesign -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row dense>
                  <v-col :cols="cols">
                    <v-card
                      height="150"
                      cols="3"
                      class="d-flex flex-column"
                      flat
                    >
                      <v-card-actions>
                        <v-btn
                          small
                          color="primary"
                          text
                          outlined
                          @click="createProject('blank')"
                          class="text-caption"
                          :disabled="projects.length >= containerLimit"
                        >
                          <v-icon left> mdi-plus-thick </v-icon>
                          {{ $t("learningDesign.newLearningDesign") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                      <v-card-actions v-if="isCourseOwner === 123">
                        <v-btn
                          small
                          color="teal lighten-2"
                          text
                          @click="createProject('template')"
                          class="text-caption"
                          :disabled="projects.length > containerLimit"
                        >
                          From template
                        </v-btn>
                      </v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card>
                  </v-col>
                  <v-col
                    :cols="cols"
                    v-bind:key="project.id"
                    v-for="project in projects"
                  >
                    <v-card
                      style="height: 100%; min-height: 150px"
                      class="d-flex flex-column"
                    >
                      <v-card-text style="padding: 0px">
                        <v-list-item
                          style="padding: 8px"
                          dense
                          color="primary"
                          :to="{ name: 'Project', params: { id: project._id } }"
                        >
                          {{
                            project.extras
                              ? project.extras.title
                              : "project title"
                          }}
                        </v-list-item>
                      </v-card-text>
                      <v-divider />
                      <v-spacer></v-spacer>
                      <v-card-actions class="text-caption">
                        <span>{{
                          new Date(project.updatedAt).toLocaleString(
                            ...DATETIME_FORMAT
                          )
                        }}</span>
                        <v-spacer></v-spacer>
                        Rev.: {{ project.revisionCount }}
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          small
                          color="red"
                          @click="deleteProject(project._id)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Delte undervisningsdesign -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row dense>
                  <v-col
                    :cols="cols"
                    v-bind:key="project.id"
                    v-for="project in sharedProjects"
                  >
                    <v-card height="150" cols="3" class="d-flex flex-column">
                      <v-card-text style="padding: 0px">
                        <v-list-item
                          style="padding: 8px"
                          dense
                          color="primary"
                          :to="{ name: 'Project', params: { id: project._id } }"
                        >
                          {{
                            project.extras
                              ? project.extras.title
                              : "project title"
                          }}
                        </v-list-item>
                      </v-card-text>
                      <v-divider />
                      <v-card-text
                        style="
                          padding: 0px;
                          padding-left: 8px;
                          padding-top: 8px;
                        "
                        class="text-caption"
                      >
                        <v-row style="padding-left: 12px; padding-top: 8px">
                          <v-col class="pa-0 ma-0">
                            <v-row cols="12" class="pa-0 ma-0">
                              <v-col cols="3" class="pa-0 ma-0">Owner:</v-col>
                              <v-col cols="9" class="pa-0 ma-0">
                                {{ project.owner.name }}
                              </v-col>
                            </v-row>
                            <v-row cols="12" class="pa-0 ma-0">
                              <v-col cols="3" class="pa-0 ma-0"
                                >Min rolle:</v-col
                              >
                              <v-col cols="9" class="pa-0 ma-0">{{
                                project.myRole
                              }}</v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <v-card-actions class="text-caption">
                        <span class="text-caption">{{
                          new Date(project.updatedAt).toLocaleString(
                            ...DATETIME_FORMAT
                          )
                        }}</span>
                        <v-spacer></v-spacer>
                        Rev.: {{ project.revisionCount }}
                        <v-spacer></v-spacer>
                        <!--
            <v-btn icon small @click="deleteProject(project._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Alle andre undervisningsdesign -->
          <v-tab-item v-if="isCourseOwner">
            <v-card flat>
              <v-card-text>
                <v-row dense>
                  <v-col
                    :cols="cols"
                    v-bind:key="project.id"
                    v-for="project in otherProjects"
                  >
                    <v-card height="150" cols="3" class="d-flex flex-column">
                      <v-card-text style="padding: 0px">
                        <v-list-item
                          style="padding: 8px"
                          dense
                          color="primary"
                          :to="{ name: 'Project', params: { id: project._id } }"
                        >
                          {{
                            project.extras
                              ? project.extras.title
                              : "project title"
                          }}
                        </v-list-item>
                      </v-card-text>
                      <v-divider />
                      <v-card-text
                        style="
                          padding: 0px;
                          padding-left: 8px;
                          padding-top: 8px;
                        "
                        class="text-caption"
                      >
                        <v-row style="padding-left: 12px; padding-top: 8px">
                          <v-col class="pa-0 ma-0">
                            <v-row cols="12" class="pa-0 ma-0">
                              <v-col cols="3" class="pa-0 ma-0">Owner:</v-col>
                              <v-col cols="9" class="pa-0 ma-0">
                                {{ project.owner.name }}
                              </v-col>
                            </v-row>
                            <!--
                <v-row cols="12" class="pa-0 ma-0">
                  <v-col cols="3" class="pa-0 ma-0">Min rolle:</v-col>
                  <v-col cols="9" class="pa-0 ma-0">{{ project.myRole }}</v-col>
                </v-row> -->
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <v-card-actions class="text-caption">
                        <span>{{
                          new Date(project.updatedAt).toLocaleString(
                            ...DATETIME_FORMAT
                          )
                        }}</span>
                        <v-spacer></v-spacer>
                        Rev.: {{ project.revisionCount }}
                        <v-spacer></v-spacer>
                        <!--
            <v-btn icon small @click="deleteProject(project._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="courseEditor" width="75%" persistent>
      <v-card>
        <v-card-title dense class="text-h5 grey lighten-2">
          <v-text-field
            :label="$t('courses.courseName')"
            hide-details="auto"
            v-model="courseUpdateData.name"
          ></v-text-field>
        </v-card-title>

        <v-card-text class="pt-4 pb-4s">
          <v-row>
            <v-col cols="3">
              <v-row>
                <v-col cols="12">
                  <label for="date-start">{{ $t("courses.startDay") }}:</label
                  ><br />
                  <input type="date" id="date-start" v-model="courseDates[0]" />
                </v-col>
                <v-col cols="12">
                  <label for="date-end">{{ $t("courses.endDay") }}:</label
                  ><br />
                  <input type="date" id="date-end" v-model="courseDates[1]" />
                </v-col>
                <v-col cols="6">
                  <label for="date-end"
                    >{{ $t("courses.containerLimit") }}:</label
                  >
                  <v-text-field
                    v-model="courseUpdateData.containerLimit"
                    hide-details
                    single-line
                    type="number"
                    min="1"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9">
              <v-tabs align-with-title v-model="courseUsersTab">
                <v-tab> {{ $t("courses.students") }} </v-tab>
                <v-tab> {{ $t("courses.teachers") }} </v-tab>
                <v-tab> {{ $t("courses.supervisors") }} </v-tab>
              </v-tabs>
              <v-tabs-items v-model="courseUsersTab">
                <v-tab-item>
                  <v-textarea
                    outlined
                    v-model="courseUpdateData.students"
                  ></v-textarea
                ></v-tab-item>
                <v-tab-item>
                  <v-textarea
                    outlined
                    v-model="courseUpdateData.teachers"
                  ></v-textarea
                ></v-tab-item>
                <v-tab-item>
                  <v-textarea
                    outlined
                    v-model="courseUpdateData.supervisors"
                  ></v-textarea
                ></v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-0 pt-0 pb-4 justify-center">
          <v-btn small color="primary" text @click="updateCourse">
            {{ $t("courses.refresh") }}
          </v-btn>
          <v-btn small color="red" text @click="courseEditor = false">
            {{ $t("courses.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";

export default {
  name: "Projects",

  data: () => ({
    tab: 0,
    invitationsReceived: [],
    invitationsSent: [],
    selectedCourse: false,
    authors: [],
    selectedAuthor: false,
    courseEditor: false,
    courseUpdateData: {},
    courseDates: [],
    courseDatesSorted: [],
    courseUsersTab: 0,
    containerLimit: 25,
  }),

  created() {
    this.DATETIME_FORMAT = [
      [],
      {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      },
    ];
  },

  async mounted() {
    this.$store.dispatch("getCourses");
    this.$store.dispatch("getProjects", this.selectedCourse);

    this.$nextTick(() => {
      this.tab = Number.parseInt(this.$route.query?.t || 0) || this.tab;
      if (this.tab < 0 || this.tab > 2) this.tab = 0;
      if (this.tab === 2 && !this.isCourseOwner) this.tab = 0;
    });
  },
  watch: {
    courseUpdateData: {
      deep: true,
      handler(val) {
        let containerLimit = Number.parseInt(val.containerLimit) || 25;
        if (containerLimit < 1) containerLimit = 1;
        if (containerLimit > 25) containerLimit = 25;
        this.$nextTick(() => {
          this.courseUpdateData.containerLimit = containerLimit;
        });
      },
    },
    courseDates(val) {
      this.courseDatesSorted = JSON.parse(JSON.stringify(val)).sort();
    },
    tab(val) {
      if (Number.parseInt(this.$route.query.t) !== val) {
        this.$router.replace({
          name: "Projects",
          query: {
            t: val,
          },
        });
      }
      let pr = [];
      const authors = {};
      if (val === 1) {
        pr = this.sharedProjects;
      }
      if (val === 2) {
        pr = this.otherProjects;
      }
      pr.forEach((p) => {
        p.users.forEach((u) => {
          if (u.role === "owner")
            authors[u.id] = {
              id: u.id,
              name: u.name,
            };
        });
      });
      this.authors = Object.values(authors);
    },
    selectedCourse(val) {
      if (!val) this.containerLimit = 25;
      this.$store.dispatch("getProjects", val);
      this.authors = [];
      this.selectedAuthor = null;

      const course = this.courses.filter((e) => e._id === val)[0] || false;

      if (course) {
        this.containerLimit = course?.extras?.containerLimit || 25;
      } else {
        this.containerLimit = 25;
      }
    },
    selectedAuthor(val) {},
  },
  methods: {
    async prepareCourseUpdate() {
      const courseData = await this.$store.dispatch(
        "getCourse",
        this.selectedCourse
      );
      const courseStudents = courseData.users
        .filter((e) => e.role === "student")
        // .map((e) => (e.name ? e.name + ", " : "") + e.email);
        .map((e) => e.email + " " + (e.name !== e.email ? e.name : ""));
      const courseTeachers = courseData.users
        .filter((e) => e.role === "teacher")
        // .map((e) => (e.name ? e.name + ", " : "") + e.email);
        .map((e) => e.email + " " + (e.name !== e.email ? e.name : ""));
      const courseSupervisors = courseData.users
        .filter((e) => e.role === "supervisor")
        // .map((e) => (e.name ? e.name + ", " : "") + e.email);
        .map((e) => e.email + " " + (e.name !== e.email ? e.name : ""));

      const course = JSON.parse(
        JSON.stringify(
          this.courses.filter((c) => c._id === this.selectedCourse)[0]
        )
      );
      course.students = courseStudents.join("\r\n");
      course.teachers = courseTeachers.join("\r\n");
      course.supervisors = courseSupervisors.join("\r\n");

      course.containerLimit = courseData?.extras?.containerLimit || 25;

      this.courseUpdateData = course;
      this.courseDates = [];
      if (course.startDate)
        this.courseDates.push(
          new Date(course.startDate).toISOString().split("T")[0]
        );
      if (course.endDate)
        this.courseDates.push(
          new Date(course.endDate).toISOString().split("T")[0]
        );
      this.courseEditor = true;
    },
    async updateCourse() {
      const courseId = this.courseUpdateData._id;
      this.containerLimit =
        Number.parseInt(this.courseUpdateData.containerLimit) || 25;
      await this.$store
        .dispatch("updateCourse", {
          courseId,
          name: this.courseUpdateData.name,
          startDate: this.courseDatesSorted[0] || null,
          endDate: this.courseDatesSorted[1] || null,
          extras: {
            containerLimit:
              Number.parseInt(this.courseUpdateData.containerLimit) || 25,
          },
        })
        .then(() => {});

      await this.$api.delete("/courses/" + courseId + "/users/all");

      let students = this.courseUpdateData.students
        .split(/\r?\n/)
        .map((e) => e.trim())
        .filter((e) => e.length > 0)
        .map((e) => {
          const ps = e.split(/\s+/);
          const email = ps.shift();
          let name = ps
            .map((e) => e.trim())
            .filter((e) => e.length > 0)
            .join(" ");
          name = name.length > 0 ? name : email;

          return { email, name, role: "student" };
        });

      let teachers = this.courseUpdateData.teachers
        .split(/\r?\n/)
        .map((e) => e.trim())
        .filter((e) => e.length > 0)
        .map((e) => {
          const ps = e.split(/\s+/);
          const email = ps.shift();
          let name = ps
            .map((e) => e.trim())
            .filter((e) => e.length > 0)
            .join(" ");
          name = name.length > 0 ? name : email;

          return { email, name, role: "teacher" };
        });

      let supervisors = this.courseUpdateData.supervisors
        .split(/\r?\n/)
        .map((e) => e.trim())
        .filter((e) => e.length > 0)
        .map((e) => {
          const ps = e.split(/\s+/);
          const email = ps.shift();
          let name = ps
            .map((e) => e.trim())
            .filter((e) => e.length > 0)
            .join(" ");
          name = name.length > 0 ? name : email;

          return { email, name, role: "supervisor" };
        });

      await this.$store.dispatch("addUsersToCourse", {
        courseId,
        users: [...students, ...teachers, ...supervisors],
      });

      await this.$store.dispatch("getCourses");
      this.courseEditor = false;
    },
    async createProject(val) {
      await this.$store
        .dispatch("createProject", {
          type: val,
          courseId: this.selectedCourse,
        })
        .then(() => {});
      await this.$store.dispatch("getProjects", this.selectedCourse);
    },
    deleteProject(id) {
      this.$store.dispatch("deleteProject", id);
      this.$store.dispatch("getProjects", this.selectedCourse);
    },
    async createCourse() {
      const name =
        this.$t("courses.newCourse") + " #" + (this.courses.length + 1);
      const course = (
        await this.$store.dispatch("createCourse", {
          name,
          extras: {
            containerLimit: 5,
          },
        })
      ).result;
      await this.$store.dispatch("getCourses");
      this.selectedCourse = course._id;
      this.$store.dispatch("getProjects", this.selectedCourse);
    },
  },

  computed: {
    isCourseOwner() {
      return ((this.user || {}).roles || []).indexOf("course_owner") !== -1;
    },
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "projects",
      "user",
      "sharedProjects",
      "otherProjects",
      "courses",
    ]),
    // eslint-disable-next-line vue/return-in-computed-property
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 4;
        case "xl":
          return 3;
      }
    },
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style>
.v-badge__badge {
  color: rgba(0, 0, 0, 0.54);
  background-color: red !important;
}
</style>
