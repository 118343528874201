<template>
  <v-container fill-height fluid class="purple darken-2">
    <v-row justify="center">
      <v-col align="center"
        ><v-img
          src="@/assets/iluks_logo_white_big.png"
          style="max-width: 250px"
          class="ma-5"
        />
        <v-btn
          v-if="isAuth !== true"
          :href="API_HOST + '/login'"
          text
          small
          color="white"
        >
          <span class="mr-2">Login</span>
          <v-icon dense>mdi-login</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";

export default {
  name: "Home",

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["isAuth"]),
  },
};
</script>
