/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
// import VuexPersistence from "vuex-persist";
import { v4 as uuidv4 } from "uuid";
import { diff } from "just-diff";

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const PRESETS_BLANK = require("./presets_blank.json");
const PRESETS_DEMO = require("./presets_demo.json");
const DESIGN_SCHEME = require("./presets_design_scheme.json");

// eslint-disable-next-line no-unused-vars
/**
const vuexLocal = new VuexPersistence({
  key: "iluks",
  storage: window.localStorage,
  reducer: (state) => {
    return { data: state.data };
  },
}); **/

Vue.use(Vuex);

const TMS = {};

export default new Vuex.Store({
  state: {
    aid: 0,
    appIsLoaded: false,
    isAuth: undefined,
    user: false,
    historyId: 0,
    prevProject: false,
    showComments: true,
    sortComments: false,
    data: {
      index: 0,
      project: {},
      projects: [],
      courses: [],
      invites: {
        inbox: [],
        sent: [],
      },
      sharedProjects: [],
      otherProjects: [],
      projectsCount: 0,
    },
  },
  mutations: {
    async getProject(state, result) {
      state.data.project = result.data.result;
      state.data.project.myRole = state.data.project.users.filter(
        (u) => u.id === state.user.id
      )?.[0]?.role;
      if (state.data.project.myRole === "owner") {
        state.data.project.canEdit = true;
        state.data.project.users = state.data.project.users.filter(
          (u) => u.id !== state.user.id
        );
      } else {
        state.data.project.canEdit = false;
      }
      state.prevProject = JSON.parse(JSON.stringify(state.data.project));
      state.prevProject = {
        data: state.prevProject.data,
        extras: state.prevProject.extras,
      };
      state.data.project.participants = [];
      if (state?.data?.project?.courseId) {
        const course = await this.dispatch(
          "getCourse",
          state?.data?.project?.courseId
        );
        if (course && course.users) {
          state.data.project.participants = (course?.users || [])
            .filter((u) => u?.role === "teacher" || u?.role === "supervisor")
            .map((u) => {
              const label =
                (u.name !== u.email && u.name?.length > 0 ? u.name : u.email) +
                " [ " +
                u.role +
                " ] ";
              return {
                label,
                email: u.email,
                role: u.role,
              };
            });
        }
      }
    },
    async createProject(state, type) {},
    async updateProject(state, project) {},
    async deleteProject(state, id) {
      const result = await Vue.prototype.$api.delete("/containers/" + id);
      this.dispatch("getProjects");
    },
    getCourses(state, result) {
      state.data.courses = [];
      (result?.data?.result || []).sort((a, b) => {
        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;
        return 0;
      });
      state.data.courses = [...result.data.result];
    },
    getProjects(state, result) {
      state.data.projects = [];
      state.data.sharedProjects = [];
      state.data.otherProjects = [];
      (result?.data?.result || []).sort((a, b) => {
        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;
        return 0;
      });
      (result?.data?.result || []).forEach((project) => {
        project.myRole = project.users.filter(
          (u) => u?.id === state.user.id
        )?.[0]?.role;
        if (project.myRole === "owner") {
          state.data.projects.push(project);
        } else {
          project.owner = project.users.filter((u) => u?.role === "owner")?.[0];
          if (project.owner) {
            if (project.myRole) {
              state.data.sharedProjects.push(project);
            } else {
              state.data.otherProjects.push(project);
            }
          }
        }
      });

      this.dispatch("updateInvites");
    },
    updateInvites(state, invites) {
      state.data.invites = invites || {};
    },
  },

  actions: {
    async checkAuth({ state }) {
      const res = await Vue.prototype.$ph(Vue.prototype.$api.get("/users"));
      if (res[1] || !res[0]?.data?.result) {
        state.isAuth = false;
        state.user = false;
      } else {
        state.isAuth = true;
        state.user = res[0].data.result;
        if (!state.user.name) {
          state.user.name = (
            state.user.firstName +
            " " +
            state.user.lastName
          ).trim();
        }
      }
    },
    async getProject({ commit }, id) {
      const result = await Vue.prototype.$api.get("/containers/" + id);
      commit("getProject", result);
    },
    async getProjects({ state, commit }, courseId) {
      const params = {};
      if ((state.user.roles || []).indexOf("course_owner") !== -1)
        params.query_mode = "all";
      const result = await Vue.prototype.$api.get("/containers/", { params });
      this.state.data.projectsCount = result.data.result.length;
      if (courseId) {
        result.data.result = result.data.result.filter(
          (e) => e.courseId === courseId
        );
      }
      commit("getProjects", result);
    },
    async getCourses({ state, commit }) {
      const params = {};
      if ((state.user.roles || []).indexOf("course_owner") !== -1)
        params.query_mode = "all";
      const result = await Vue.prototype.$api.get("/courses/", { params });
      commit("getCourses", result);
    },
    async deleteCourses({ state, commit }, id) {
      const params = {};
      if (state.user.roles.indexOf("course_owner") !== -1)
        params.query_mode = "all";
      const result = await Vue.prototype.$api.get("/courses/", { params });
      commit("getCourses", result);
    },
    async updateInvites({ state, commit }) {
      const invites = {
        inbox: [],
        sent: [],
      };
      try {
        let res = await Vue.prototype.$ph(Vue.prototype.$api.get("/invites/"));
        if (typeof res[1] === "undefined" && res[0]?.data?.result) {
          res = res[0].data.result
            .filter((i) => i.status === "pending")
            .map((inv) => {
              inv.subjectTitle =
                inv?.containerExtras?.title || inv.inviteSubject;
              return inv;
            });
          invites.inbox = res.filter((i) => i.createdBy !== state.user.id);
          invites.sent = res.filter((i) => i.createdBy === state.user.id);
        }
      } catch (error) {}
      commit("updateInvites", invites);
    },
    async createProject({ commit }, { type, courseId }) {
      const data = {
        title:
          // "Nytt undervisningsdesign #" + (this.state.data.projectsCount + 1),
          "Nytt undervisningsdesign #" + (this.state.data.projects.length + 1),
        config: clone(
          (type === "blank" ? PRESETS_BLANK.config : PRESETS_DEMO.config) || {}
        ),
        lists: clone(
          type === "blank" ? PRESETS_BLANK.lists : PRESETS_DEMO.lists
        ),
        objects: clone(
          type === "blank" ? PRESETS_BLANK.objects : PRESETS_DEMO.objects
        ),
      };
      const result = await Vue.prototype.$api.post("/containers/", {
        courseId: courseId || null,
        // data,
        // extras: { title: data.title },
      });
      const project = result?.data?.result || {};
      await this.dispatch("updateProject", {
        _id: project._id,
        data,
        // courseId: "63b57f50fba63ccb504fcb6c",
        extras: { title: data.title },
        delay: false,
      });
    },
    deleteProject({ commit }, id) {
      commit("deleteProject", id);
    },
    async updateProject({ commit, state }, { _id, data, extras, delay }) {
      // window.diff = diff;
      // const prevProject = JSON.parse(JSON.stringify(state.prevProject));
      // const newProject = JSON.parse(JSON.stringify({ data, extras }));
      return new Promise(function (resolve, reject) {
        if (TMS[_id]) {
          clearTimeout(TMS[_id]);
        }
        TMS[_id] = setTimeout(
          async () => {
            const result = await Vue.prototype.$api.post("/containers/" + _id, {
              data,
              extras,
            });
            resolve(result);
          },
          delay ? 750 : 0
        );
      });
    },
    async createCourse({ commit }, params) {
      const result = await Vue.prototype.$api.post("/courses/", {
        name: params.name,
        extras: params.extras || {},
      });
      return result.data;
    },
    async getCourse({ commit }, courseId) {
      const result = await Vue.prototype.$api.get("/courses/" + courseId);
      return result.data.result;
    },
    async addUsersToCourse({ commit }, params) {
      const result = await Vue.prototype.$api.patch(
        "/courses/" + params.courseId + "/users",
        {
          users: params.users,
        }
      );
    },
    async updateCourse({ commit }, params) {
      const result = await Vue.prototype.$api.patch(
        "/courses/" + params.courseId,
        {
          name: params.name,
          startDate: params.startDate || null,
          endDate: params.endDate || null,
          extras: params.extras || {},
        }
      );
    },
  },
  modules: {},
  getters: {
    isAuth: (state) => state.isAuth,
    user: (state) => state.user,
    invites: (state) => state.data.invites,
    projects: (state) => state.data.projects,
    courses: (state) => state.data.courses,
    sharedProjects: (state) => state.data.sharedProjects,
    otherProjects: (state) => state.data.otherProjects,
    project: (state) => state.data.project,
    DESIGN_SCHEME: () => DESIGN_SCHEME,
    appIsLoaded: (state) => state.appIsLoaded,
    showComments: (state) => state.showComments,
    sortComments: (state) => state.sortComments,
    isAdmin: (state) =>
      state?.isAuth &&
      (state?.user?.roles || []).indexOf("user_administrator") !== -1,
  },
  // plugins: [vuexLocal.plugin],
});
