/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

// import MediumEditor from "vuejs-medium-editor";

import axios from "axios";

import translations from "./store/translations";

const edjsHTML = require("editorjs-html");
Vue.prototype.$edjsParser = edjsHTML();

Vue.config.productionTip = false;

Vue.appIsLoaded = false;

Vue.prototype.$ph = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};

Vue.use(VueI18n);
const seachParams = new URLSearchParams(window.location.search);
const langParam = localStorage.getItem("lang");
const lang = ["en", "no"].indexOf(langParam) > -1 ? langParam : "no";
localStorage.setItem("lang", lang);
const i18n = new VueI18n({
  locale: lang, // set locale
  messages: translations, // set locale messages
});

Vue.prototype.$http = axios;

const MAIN_HOST = (() => {
  let tmp = window.location.host;
  tmp = tmp.slice(tmp.indexOf(".") + 1);
  return tmp;
})();

Vue.prototype.$checkAuth = async ({ to, from, next }) => {
  const check = await Vue.prototype.$ph(
    Vue.prototype.$http(Vue.prototype.API_HOST + "/ping", {
      withCredentials: true,
    })
  );
  // const response = check[0] || check[1].response;
  // if (response?.data?.statusCode === 200) {
  if (typeof check[1] === "undefined") {
    await store.dispatch("checkAuth");
    if (store.state?.isAuth) {
      if (to.name === "Home") {
        next({ name: "Projects" });
      } else {
        next();
      }
    } else {
      location.replace(Vue.prototype.API_HOST + "/logout");
    }
  } else {
    if (check[1]?.response?.data?.uuid) {
      location.replace(Vue.prototype.API_HOST + "/logout");
    } else {
      if (to.name !== "Home") {
        next({ name: "Home", query: null });
      } else {
        next({ query: null });
      }
    }
  }
};

Vue.prototype.API_HOST = window.location.protocol + "//api." + MAIN_HOST;
Vue.prototype.API_BASE_URL = Vue.prototype.API_HOST + "/v1";

Vue.prototype.$api = axios.create({
  baseURL: Vue.prototype.API_BASE_URL,
  withCredentials: true,
});

// Vue.component("medium-editor", MediumEditor);

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
