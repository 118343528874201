export default {
  en: {
    buttons: {
      addNewElement: "Add new element",
      acceptDelete: "Delete",
      rejectDelete: "Cancel",
    },
    card: {
      titlePlaceHolder: "Enter card name...",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
    invites: {
      title: "Invites",
      sent: "Sent",
      recipient: "Recipient",
      document: "Document",
      role: "Role",
      received: "Received",
      from: "From",
      accept: "Accept",
      reject: "Reject",
    },
    messageToAdministrator: {
      title: "Message to administrator",
      placeholder: "Enter a message text of at least 12 letters",
      send: "Send",
    },
    courses: {
      myCourses: "My courses",
      newCourse: "New course",
      courseName: "Course name",
      startDay: "Start day",
      endDay: "End day",
      students: "Students",
      teachers: "Teachers",
      supervisors: "Supervisors",
      refresh: "Refresh",
      cancel: "Cancel",
      containerLimit: "Limit of designs per student",
    },
    learningDesign: {
      myLearningDesigns: "My<br />learning designs",
      sharedLearningDesigns: "Shared<br />learning designs",
      allOtherLearningDesigns: "All other<br /> learning designs",
      newLearningDesign: "New learning design",
      viewDocument: "View complete learning design",
      viewTable: "Table display",
      hideComments: "Click to hide comments",
      showComments: "Click to show comments",
      sortCommentsAsc: "Click to show new comments last",
      sortCommentsDesc: "Click to show new comments first",
      participants: "Participants",
      deleteParticipant: "Do you want to delete a participant?",
      newParticipantEmail: "New participant's email",
      newParticipantOrEmail: "New participant or email",
      inviteSupervisor: "Invite supervisor",
      inviteTeacher: "Invite teacher",
      feedback: "Feedback on learning design",
      leaveComment: "Leave a comment",
      downloadLogsFile: "Download logs",
    },
    columns: {
      "teaching-design": {
        title: "Learning design",
        tooltips: [
          "Compose a learning design by dragging and dropping cards from the lists",
        ],
        newCard: "",
      },
      kompetansemaal: {
        title: "Learning goals",
        tooltips: [
          "Describe the learning goals and/or the skills students should achieve",
        ],
        newCard: "Add learning goal",
      },
      trinn: {
        title: "Student prerequisites",
        tooltips: [
          "Describe year level, study program and student prerequisites",
        ],
        newCard: "Add student prerequisites",
      },
      fag: {
        title: "Content",
        tooltips: [
          "Add the subject and describe the academic content the students will acquire",
        ],
        newCard: "Add content",
      },
      laremidler: {
        title: "Learning resources",
        tooltips: [
          "Describe the learning resources you will use in the teaching",
        ],
        newCard: "Add learning resource",
      },
      vurdeging: {
        title: "Assessment",
        tooltips: [
          "Describe forms of assessment and how the assessment activity is to be carried out",
        ],
        newCard: "Add assessment",
      },
      adrbeidsmater: {
        title: "Activities",
        tooltips: [
          "Describe the activities you will carry out in the lesson",
          "Enter the time spent on each activity",
        ],
        newCard: "Add activity",
      },
    },
  },
  no: {
    buttons: {
      addNewElement: "Lag et nytt element",
      acceptDelete: "Godta",
      rejectDelete: "Avbryt",
    },
    card: {
      titlePlaceHolder: "Skriv inn navnet ditt...",
      save: "Lagre",
      cancel: "Avbryt",
      delete: "Slett",
    },
    invites: {
      title: "Inviterer",
      sent: "Sendt",
      recipient: "Mottaker",
      document: "Dokument",
      role: "Rolle",
      received: "Mottatt",
      from: "Fra",
      accept: "Godta",
      reject: "Avvis",
    },
    messageToAdministrator: {
      title: "Melding til administrator",
      placeholder: "Skriv inn en meldingstekst på minst 12 bokstaver",
      send: "Sende",
    },
    courses: {
      myCourses: "Mine kurs",
      newCourse: "Nytt course",
      courseName: "Kursnavn",
      startDay: "Startdato",
      endDay: "Sluttdato",
      students: "Studenter",
      teachers: "Lærere",
      supervisors: "Veiledere",
      refresh: "Forfriske",
      cancel: "Avbryt",
      containerLimit: "Begrensning av design per student",
    },
    learningDesign: {
      myLearningDesigns: "Mine<br />undervisningsdesign",
      sharedLearningDesigns: "Delte<br />undervisningsdesign",
      allOtherLearningDesigns: "Alle andre<br />undervisningsdesign",
      newLearningDesign: "Nytt undervisningsdesign",
      viewDocument: "Dokumentvisning",
      viewTable: "Tabellvisning",
      hideComments: "Trykk for å skjule kommentarer",
      showComments: "Trykk for å vise kommentarer",
      sortCommentsAsc: "Klikk for å vise nye kommentarer sist",
      sortCommentsDesc: "Klikk for å vise nye kommentarer først",
      participants: "Deltakere",
      deleteParticipant: "Vil du slette en deltaker?",
      newParticipantEmail: "Ny deltakers e-post",
      newParticipantOrEmail: "Ny deltakers elle e-post",
      inviteSupervisor: "Inviter veileder",
      inviteTeacher: "Inviter en lærer",
      feedback: "Tilbakemelding undervisningsdesign",
      leaveComment: "Legg igjen kommentarer",
      downloadLogsFile: "Last ned endringslogg",
    },
    columns: {
      "teaching-design": {
        title: "Undervisningsdesign",
        tooltips: [
          "Sett sammen undervisningsdesignet ved å dra og slippe kort fra listene",
        ],
        newCard: "",
      },
      kompetansemaal: {
        title: "Kompetansemål",
        tooltips: [
          "Beskriv kompetansemål og/eller ferdigheter som elevene skal oppnå etter undervisningen",
        ],
        newCard: "Legg til kompetansemål",
      },
      trinn: {
        title: "Elevforutsetninger",
        tooltips: [
          "Beskriv årstrinn, studieprogram og elevgruppens forutsetninger",
        ],
        newCard: "Legg til elevforutsetning",
      },
      fag: {
        title: "Innhold",
        tooltips: [
          "Sett inn fag og beskriv det faglige innholdet elevene skal tilegne seg",
        ],
        newCard: "Legg til innhold",
      },
      laremidler: {
        title: "Læremidler",
        tooltips: ["Beskriv læremidlene du vil benytte i undervisningen"],
        newCard: "Legg til læremiddel",
      },
      vurdeging: {
        title: "Vurdering",
        tooltips: [
          "Beskriv vurderingsformer og hvordan vurderingsaktiviteten skal gjennomføres",
        ],
        newCard: "Legg til vurderingsform",
      },
      adrbeidsmater: {
        title: "Arbeidsmåter",
        tooltips: [
          "Beskriv aktivitetene du vil gjennomføre i undervisningen",
          "Angi tidsbruk for hver aktivitet",
        ],
        newCard: "Legg til arbeidsmåte",
      },
    },
  },
};
