<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab class="text-caption">
            <v-badge
              :content="'[' + courses.length + ']'"
              tile
              inline
              color="transparent"
            >
              Mine<br />kurs</v-badge
            >
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <!-- Mine undervisningsdesign -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row dense>
                  <v-col :cols="cols" v-if="isCourseOwner">
                    <v-card
                      height="150"
                      cols="3"
                      class="d-flex flex-column"
                      flat
                    >
                      <v-card-actions>
                        <v-btn
                          small
                          color="primary"
                          text
                          outlined
                          @click="createCourse()"
                          class="text-caption"
                        >
                          <v-icon left> mdi-plus-thick </v-icon>
                          Nytt kurs
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card>
                  </v-col>
                  <v-col
                    :cols="cols"
                    v-bind:key="course._id"
                    v-for="course in courses"
                  >
                    <v-card
                      style="height: 100%; min-height: 150px"
                      class="d-flex flex-column"
                    >
                      <v-card-text style="padding: 0px">
                        {{ course }}
                        <v-list-item
                          style="padding: 8px"
                          dense
                          color="primary"
                          :to="{
                            name: 'Projects',
                            // params: { id: course._id },
                            query: { course: course._id },
                          }"
                        >
                          {{ course.name }} [
                          {{ course.owners.map((e) => e.name).join(", ") }}
                          ]
                        </v-list-item>
                      </v-card-text>
                      <v-divider />
                      <v-spacer></v-spacer>
                      <!--
                      <v-card-actions class="text-caption">
                        <span>{{
                          new Date(project.updatedAt).toLocaleString(
                            ...DATETIME_FORMAT
                          )
                        }}</span>
                        <v-spacer></v-spacer>
                        Rev.: {{ project.revisionCount }}
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          small
                          color="red"
                          @click="deleteProject(project._id)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions> -->
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";

export default {
  name: "Courses",

  data: () => ({
    tab: 0,
    invitationsReceived: [],
    invitationsSent: [],
  }),

  created() {
    this.DATETIME_FORMAT = [
      [],
      {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      },
    ];
  },

  async mounted() {
    this.$store.dispatch("getCourses");
    this.$store.dispatch("getProjects");
    this.$nextTick(() => {
      this.tab = Number.parseInt(this.$route.query?.t || 0) || this.tab;
      if (this.tab < 0 || this.tab > 2) this.tab = 0;
      if (this.tab === 2 && !this.isCourseOwner) this.tab = 0;
    });
  },
  watch: {
    tab(val) {
      if (Number.parseInt(this.$route.query.t) !== val) {
        this.$router.replace({
          name: "Projects",
          query: {
            t: val,
          },
        });
      }
    },
  },
  methods: {
    deleteProject(id) {
      this.$store.dispatch("deleteProject", id);
      this.$store.dispatch("getProjects");
    },
    clearStorage() {
      localStorage.clear();
      window.location.reload();
    },
    async createCourse() {
      const name = "Nytt course #" + (this.courses.length + 1);
      const users = [
        {
          email: "gleb@belokrys.org",
          name: "Gleb Belokrys",
          role: "student",
        },
      ];
      const course = (await this.$store.dispatch("createCourse", { name }))
        .result;
      await this.$store
        .dispatch("addUsersToCourse", { courseId: course._id, users })
        .then(() => {});
      await this.$store.dispatch("getCourses");
    },
  },

  computed: {
    isCourseOwner() {
      return ((this.user || {}).roles || []).indexOf("course_owner") !== -1;
    },
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "projects",
      "courses",
      "user",
      "sharedProjects",
      "otherProjects",
    ]),
    // eslint-disable-next-line vue/return-in-computed-property
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 4;
        case "xl":
          return 3;
      }
    },
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style>
.v-badge__badge {
  color: rgba(0, 0, 0, 0.54);
  background-color: red !important;
}
</style>
