<template>
  <projects />
</template>

<script>
import Projects from "../components/Projects";

export default {
  name: "Home",

  components: {
    Projects,
  },
};
</script>
